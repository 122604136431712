/* ./src/App.css */

@import url("https://use.typekit.net/kgh1rbq.css");

body {
/*  font-family: linotype-sabon, sans-serif;
  font-weight: 400;*/
font-family: "loretta-variable", sans-serif;
font-variation-settings: "wght" 300;
  position: fixed;
  width: 100%;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.haiku-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
/*  background: #ededed;*/
  background: #181C1E;
}

.haiku {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.haiku-line {
  font-size: 2.2rem;
  color: #A1B9B5;
  text-align: center;
  margin: 0;
  margin-bottom: 1.25rem;
}

.haiku-word {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 0.3s ease-in-out;
  animation: 0.3s linear fadeIn backwards;
}

.haiku-word:hover {
  cursor: pointer;
  color: #fff;
  transition: color 0.3s ease-in-out;
  -webkit-tap-highlight-color: transparent;
}

/*@media (hover: none) {
.haiku-word:hover {
color: #614A58;
transition: color 0.8s ease-in-out;
}
.haiku-word:active{
	color: #fff;
transition: color 0.8s ease-in-out;
}
}*/

.clicked {
  color: #fff;
}

.hidden {
  opacity: 0 !important;
}

@media screen and (max-width: 768px) {
  .haiku-line {
    font-size: 1.5rem;
  }
}
